import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import {
  BodyDeleteDocumentExpediente,
  FilterDocumentCatalogue,
  InputItemExpedienteFileCar,
  ItemCatalogueExpedienteCar,
  UpdateItemExpedienteFileCar,
} from '@core/models/expediente-car.model';
import { ResponseBackAzure } from '@core/models/responsehttp.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ExpedienteService {
  private readonly _http = inject(HttpClient);
  httpParam = new HttpParams();

  private readonly initFilter = {
    tipo_operacion: undefined,
    tipo_transaccion: undefined,
    id: undefined,
    etapa: undefined,
  };
  constructor() {
    this.httpParam = this.httpParam.append('backend', 'azure');
  }

  getCatalogue(filtros?: { tipo?: string; origen?: string; etapa?: string }) {
    const queryParams = `tipo=${filtros?.tipo ?? ''}&origen=${filtros?.origen ?? ''}&etapa=${filtros?.etapa ?? ''}`;

    return this._http.get<ResponseBackAzure>(
      `${environment.API_URL_MANAGEMENT}/v2/mesa-control/documentos/catalogo?${queryParams}`,
      {
        params: this.httpParam,
      }
    );
  }

  postCatalogue(catalogo: ItemCatalogueExpedienteCar) {
    return this._http.post<ResponseBackAzure>(
      `${environment.API_URL_MANAGEMENT}/v2/mesa-control/documentos/catalogo?tipo=`,
      catalogo,
      {
        params: this.httpParam,
      }
    );
  }

  getDocumentsByCarNCitaNdocumentId(autoId?: string, documentoId?: string, id_cita?: string) {
    const queryParams = `id_automovil=${autoId ?? ''}&id_documento=${documentoId ?? ''}&id_cita=${id_cita ?? ''}`;

    return this._http.get<ResponseBackAzure>(
      `${environment.API_URL_MANAGEMENT}/v2/mesa-control/documentos?${queryParams}`,
      {
        params: this.httpParam,
      }
    );
  }

  postDocumentByCar(file: InputItemExpedienteFileCar) {
    return this._http.post<ResponseBackAzure>(`${environment.API_URL_MANAGEMENT}/v2/mesa-control/documentos`, file, {
      params: this.httpParam,
    });
  }

  putDocumentByCar(file: UpdateItemExpedienteFileCar) {
    return this._http.put<ResponseBackAzure>(`${environment.API_URL_MANAGEMENT}/v2/mesa-control/documentos`, file, {
      params: this.httpParam,
    });
  }

  deleteDocument(list_url: string[]) {
    return this._http.delete<ResponseBackAzure>(`${environment.API_URL_MANAGEMENT}/v2/web/storage/loadimage`, {
      body: { list_url },
      params: this.httpParam,
    });
  }

  deleteDocumentV2(body: BodyDeleteDocumentExpediente) {
    return this._http.delete<ResponseBackAzure>(`${environment.API_URL_MANAGEMENT}/v2/mesa-control/documentos`, {
      body,
      params: this.httpParam,
    });
  }

  getImagenesAutoExistentesOrdenadas(idAutomovil: string) {
    return this._http.get(`${environment.API_URL_MANAGEMENT}/mesacontrol/imagenes/${idAutomovil}?backend=azure-crm`);
  }

  validacionFotos(body) {
    return this._http.post(`${environment.API_URL_MANAGEMENT}/mesacontrol/imagenes/validacion?backend=azure-crm`, body);
  }
}
